import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import Markdown from 'markdown-to-jsx';
import styled, { ThemeProvider } from "styled-components/macro";
import { TermsOfServiceRequired } from "./generated/idp/api/idp";
import { Button, Primary } from "./Button";
import { idpc } from "./api";

interface initState {
    state: "init";
}

interface acceptingState {
    state: "accepting";
}

interface errorState {
    state: "error";
    error: string;
}

type state = initState | acceptingState | errorState;

interface acceptedAction {
    type: "accepted";
}

interface errorAction {
    type: "error";
    error: string;
}

type action = acceptedAction | errorAction;

function reducer(state: state, action: action): state {
    switch (action.type) {
        case "accepted":
            return { state: "accepting" };
        case "error":
            return { state: "error", error: action.error };
        default:
            return state;
    }
}

const ToSContainer = styled.div`
    max-height: 50vh;
    overflow-y: scroll;
    scrollbar-width: thin;
`;

export function TermsOfService(props: {
    onDone: () => void;
    tosRequired: TermsOfServiceRequired
}): JSX.Element {
    const [state, dispatch] = useReducer(reducer, { state: "init" });
    const onAccept = useCallback(async () => {
        dispatch({ type: "accepted" });
        try {
            await idpc.AcceptToS({tosId: props.tosRequired.id });
            props.onDone();
        } catch (err) {
            dispatch({ type: "error", error: err.toString() });
        }
    }, [props.tosRequired])
    return (
        <>
            <p>
                Bitte lesen und akzeptieren Sie die Nutzungsbedingungen um fortzufahren:
            </p>
            <hr />
            <ToSContainer>
                <Markdown>{props.tosRequired.terms}</Markdown>
            </ToSContainer>
            <hr />
            <div style={{ textAlign: "center" }}>
                <Button onClick={onAccept} color={Primary}>Akzeptieren</Button>
            </div>
        </>
    );
}