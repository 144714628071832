import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AuthContinue } from "./generated/idp/api/idp";
import { TermsOfService } from "./TermsOfService";
import { EmailEnroll } from "./login/Mail";

export function needsEnrolment(authContinue: AuthContinue): boolean {
    return authContinue.enrollEmail || authContinue.termsOfServiceRequired != undefined;
}

export function Enrolment(props: { authContinue: AuthContinue; onDone: () => void; }): JSX.Element {
    const [toEnrol, setToEnrol] = useState(() => {
        let e: string[] = [];
        if (props.authContinue.termsOfServiceRequired != undefined) {
            e.push("tos");
        }
        if (props.authContinue.emailEnrollment) {
            e.push("email");
        }
        if (e.length == 0) {
            props.onDone();
        }
        return e;
    });
    const onDone = useCallback(() => {
        const newEnrol = toEnrol.slice(1);
        if (newEnrol.length == 0) {
            props.onDone();
            return
        }
        setToEnrol(newEnrol);
    }, [props.onDone]);

    switch (toEnrol[0]) {
        case "tos":
            return (<TermsOfService onDone={onDone} tosRequired={props.authContinue.termsOfServiceRequired!} />);
        case "email":
            return (<EmailEnroll onDone={onDone} policy={props.authContinue.emailEnrollment} />);
        default:
            throw Error("logic bug");
    }
}